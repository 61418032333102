import React, { useEffect, useState } from 'react'
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { navigate, graphql, Link } from 'gatsby'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import { checkIfSubmittable, validation } from '../../utils/validation'
import emailIcon from '../assets/images/email.svg'
import googleIcon from '../assets/images/google_logo.svg'
import appleIcon from '../assets/images/apple_logo.svg'
// import facebookIcon from '../assets/images/facebook_logo.svg'
// import birdAvatar from '../assets/images/chirpyest-white.svg'
import blackLogo from '../assets/images/chirpyest-logo.svg'
import axios from '../axios'
import CHRButton from '../components/button'
import CHRInput from '../components/input'
import IOSSwitch from '../components/IOSSwitch'
import SEO from '../components/seo'
import CHRText from '../components/typography'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/joinsStyles'
import ContentContainer from './../components/contentContainer'
import { Typography } from '@material-ui/core'
import {
  ENDPOINTS,
  ROUTES,
  TYPOGRAPHY_ALIGNMENT,
  TYPOGRAPHY_SIZES,
  EXTENSION_ID,
  baseURL,
  FONTS,
} from './../constants'
import BlockUserInterface from './../components/blockUserInterface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import PinExtensionReminderPopOut from '../components/popOuts/pinExtensionReminderPopOut'
import { useAppState } from '../appState'
import { isUserAuthenticated } from './../../utils/isUserAuthenticated'
import { sendExtensionMessage } from './../../utils/extensionHelper'
import { useLocation } from '@reach/router'
import CHRFloatImageLeft from '../components/floatImageLeft'
import CHRFloatImageRight from '../components/floatImagRight'
import CHRSectionContainer from './../components/sectionContainer'
import CHROutlinedButton from './../components/outlinedButton'
import CHRArrowButton from './../components/arrowButton'
import clx from 'classnames'
import * as moment from 'moment'
import { TrackingCase } from '../../utils/trackingCases'
import { setItemToLocalStorage } from '../../utils/setLocalStorage'
import { tracker } from '../systemLogs'
import SignInModal from '../components/signInModal'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

const referralIdRegex = /(.*-.*)/
interface JoinProps {
  t: TFunction
  data: any
}

const Join = ({ t, data }: JoinProps) => {
  const [email, setEmail] = useState('')
  const [appState] = useAppState()
  const [password, setPassword] = useState('')
  const [isTermsChecked, setIsTermsChecked] = useState(true)
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isExtensionDownloaded, setIsExtensionDownloaded] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [gateEnabled, setGateEnabled] = useState(false)
  const [state, dispatch] = useAppState()
  const [formErrors, setFormErrors] = useState({
    emailValidationError: false,
    emailErrorText: '',
    passwordValidationError: false,
    passwordErrorText: '',
    requestError: false,
    requestErrorText: '',
  })
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [inviteAccepted, setInviteAccepted] = useState(false)
  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [param] = window.location.search.slice(1).split('&')
  const { pathname } = useLocation()
  const referralId = pathname?.split('/')[2]?.split('#')[0] || ''

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  // redirect user to members page instead of join page
  useEffect(() => {
    if (appState.userId) navigate(ROUTES.member) // Commented because we need to access join page even if we are logged in
  }, [])

  useEffect(() => {
    if (referralId) {
      setItemToLocalStorage(
        'referralId',
        JSON.stringify({ id: referralId, time: moment().format() })
      )
    }

    getSettings().then(() => {
      if (
        param.split('=')[0] === 'fromExtension' &&
        param.split('=')[1] === 'true'
      ) {
        setItemToLocalStorage('extensionDownloaded', 'true')
        isUserAuthenticated().then(result => {
          if (!!result) {
            navigate(ROUTES.member, { state: { showPopUp: true } })
          } else {
            setIsModalOpen(true)
            setIsExtensionDownloaded(true)
            setIsPageLoading(false)
          }
        })
      } else {
        setIsPageLoading(false)
        const isExtensionInstalled = window.localStorage.getItem(
          'extensionDownloaded'
        )
        if (isExtensionInstalled && isExtensionInstalled === 'true') {
          setIsExtensionDownloaded(true)
        }
        return
      }
    })
  }, [])

  // TODO: useless function, remove it or use it in the future
  const getSettings = async () => {
    try {
      const settings = await axios.get(`${baseURL}${ENDPOINTS.settings}`)
      setGateEnabled(settings?.data?.data[0]?.setting_value?.gateEnabled)
    } catch (err) {
      setGateEnabled(true)
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const closeSignInModal = () => {
    setIsOpenSignInModal(false)
  }

  const handleAutoSignIn = async (email: string, password: string) => {
    setIsSubmitting(true)
    try {
      const user = await Auth.signIn(email, password)

      if (!user.signInUserSession.accessToken) {
        throw new Error(t('messages.notAuthorized'))
      }

      const {
        data: { data },
      } = await axios.get(
        ENDPOINTS.user.replace(':id', user.attributes['custom:user_id'])
      )
      const currentUserInfo = await Auth.currentCredentials()
      const session = await Auth.currentSession()
      const userProfile = {
        email: data.email,
        userName: data.username,
        fullName: data.name,
        imageKey: data.image,
        imageUrl: '' as Object | string,
        isPro: data.isPro,
        isCreator: data.isCreator,
        isReferralBonus: data.isReferralBonus,
        userDoublePayment: data.userDoublePayment,
        financialData: data.financialData || {
          pending: '0',
          lastClosedOut: '0',
          receivableMilestone: '0',
        },
        loginProvider: data.loginProvider,
      }

      if (data.image && currentUserInfo?.identityId) {
        const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
        userProfile.imageUrl = url
      }

      sendExtensionMessage({ session })

      if (window.chrome?.runtime?.sendMessage) {
        window.chrome.runtime.sendMessage(
          EXTENSION_ID,
          { session },
          _response => {}
        )
      }

      dispatch({ type: 'SIGN_IN', auth: user, userProfile })

      const urlParams = new URLSearchParams(window.location.search)
      const signInFromExtension = urlParams.get('signInFromExtension')

      navigate(
        `${ROUTES.member}${
          signInFromExtension ? '?signInFromExtension=signInFromExtension' : ''
        }`
      )
    } catch (err) {
      setFormErrors({
        requestError: true,
        requestErrorText: 'An account with this email already exists',
      })
    }
    setIsSubmitting(false)
  }

  const handleSignUp = async () => {
    setIsSubmitting(true)
    setFormErrors({
      emailValidationError: false,
      emailErrorText: '',
      passwordValidationError: false,
      passwordErrorText: '',
      requestError: false,
      requestErrorText: '',
    })
    try {
      const isFormValid = await validation({ email, password })
      if (isTermsChecked && isFormValid) {
        let userData = {
          email,
          password,
          termsCondsAccepted: isTermsChecked,
          newsletterSubscription: isNewsletterChecked,
          extensionDownloaded: isExtensionDownloaded,
        }
        if (referralId) {
          Object.assign(userData, { referral: referralId })
        } else {
          const referralIdFallBackJSON = window.localStorage.getItem(
            'referralId'
          )
          const referralIdFallBack = JSON.parse(referralIdFallBackJSON)
          if (referralIdFallBack) {
            const { id, time } = referralIdFallBack
            const referralIdAge = moment().diff(moment(time), 'days')
            if (referralIdAge <= 3) {
              Object.assign(userData, { referral: id })
            }
          }
        }
        tracker.track(TrackingCase.UserTracking, `POST ${ENDPOINTS.signUp}`, {
          location: 'Join',
          email,
        })
        await axios.post(ENDPOINTS.signUp, userData)
        const user = await Auth.signIn(email, password)

        setItemToLocalStorage('signUp', 'firstVisit')
        setItemToLocalStorage('firstMove', 'true')

        if (user) {
          const currentSession = await Auth.currentSession()

          if (!currentSession) {
            navigate(ROUTES.signIn)
          } else {
            const {
              data: { data },
            } = await axios.get(
              ENDPOINTS.user.replace(':id', user.attributes['custom:user_id'])
            )
            const userProfile = {
              email: data.email,
              userName: data.username,
              fullName: data.name,
              imageKey: data.image,
              imageUrl: '' as Object | string,
              isPro: data.isPro,
              isCreator: data.isCreator,
              isReferralBonus: data.isReferralBonus,
              userDoublePayment: data.userDoublePayment,
              financialData: data.financialData || {
                pending: '0',
                lastClosedOut: '0',
                receivableMilestone: '0',
              },
              loginProvider: data.loginProvider,
            }

            if (data.image && currentSession?.identityId) {
              const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
              userProfile.imageUrl = url
            }
            sendExtensionMessage({ session: currentSession })
            if (window.chrome?.runtime?.sendMessage) {
              window.chrome.runtime.sendMessage(
                EXTENSION_ID,
                { session: currentSession },
                _response => {}
              )
            }

            dispatch({ type: 'SIGN_IN', auth: user, userProfile })
            navigate(ROUTES.personalInfo)
          }
        }
      }
    } catch (error) {
      const responseError = error.response

      if (error.errors) {
        if (error.inner[0]?.params.path === 'email') {
          setFormErrors({
            emailValidationError: true,
            emailErrorText: error.inner[0]?.message,
            passwordValidationError:
              error.inner[1]?.params?.path === 'password',
            passwordErrorText: error.inner[1]?.message,
          })
        } else {
          setFormErrors({
            passwordErrorText: error.inner[0]?.message,
            passwordValidationError:
              error.inner[0]?.params?.path === 'password',
          })
        }
      } else {
        let errMsg = t('join.errorCreatingANewAccount')
        if (responseError) {
          const loginProvider =
            responseError.data.loginProvider?.toLowerCase() || ''
          const socialProvider = loginProvider.includes('google')
            ? 'Google'
            : 'Apple'
          const isConflict = responseError.status === 409
          const isSocialProvider =
            loginProvider.includes('google') || loginProvider.includes('apple')

          if (isConflict && isSocialProvider) {
            errMsg = `You have already singed in with this email with ${socialProvider}`
            setTimeout(() => {
              setEmail('')
              setPassword('')
              setFormErrors({
                emailValidationError: false,
                emailErrorText: '',
                passwordValidationError: false,
                passwordErrorText: '',
                requestError: false,
                requestErrorText: '',
              })
              setIsOpenSignInModal(true)
            }, 3000)
          } else if (isConflict) {
            return handleAutoSignIn(email, password)
          }
        }

        setFormErrors({
          requestError: true,
          requestErrorText: errMsg,
        })
      }
    }

    setIsSubmitting(false)
  }

  const classes = styles()

  const handleNewslettersSwitch = () => {
    setIsNewsletterChecked(!isNewsletterChecked)
  }

  const handleTermsSwitch = () => {
    setIsTermsChecked(!isTermsChecked)
  }

  if (isPageLoading) {
    return <BlockUserInterface />
  }

  const isInvite =
    referralId && !inviteAccepted && referralIdRegex.test(referralId)

  const JoinArrowButton = ({ isFloatRight }: { isFloatRight: boolean }) => (
    <div
      className={clx({
        [classes.customWidth7]: isFloatRight,
      })}
    >
      <div
        className={clx(classes.addChirpyestBtn, {
          [classes.floatRightButton]: isFloatRight,
        })}
      >
        <CHRArrowButton
          label={t('shared.join')}
          onClick={() => {
            setInviteAccepted(true)
            window.scroll({
              top: 0,
              behavior: 'smooth',
            })
          }}
          hoverEffects={true}
        />
      </div>
    </div>
  )

  return (
    <>
      {!isPageLoading && (
        <Layout isJoinPage={isInvite}>
          <SEO
            title={t('join.metaTitle')}
            description={t('join.metaDescription')}
          />
          <ContentContainer>
            {isInvite ? (
              <div className={classes.coloredHeader}>
                <div
                  className={[
                    classes.container,
                    classes.welcomeMessageContainer,
                    classes.edgeSpace,
                  ].join(' ')}
                >
                  <CHRText
                    size={TYPOGRAPHY_SIZES.big}
                    align={TYPOGRAPHY_ALIGNMENT.left}
                    style={{
                      marginBottom: 10,
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className={[
                        classes.findYourVoiceContainer,
                        classes.desktop,
                        classes.whiteText,
                      ].join(' ')}
                    >
                      {t('join.welcomeToChirpyest')}
                    </span>
                    <span
                      className={[
                        classes.welcomeMessageTypography,
                        classes.mobile,
                        classes.whiteText,
                      ].join(' ')}
                    >
                      {t('join.welcomeToChirpyest')}
                    </span>
                  </CHRText>
                  <CHRText
                    align="center"
                    className={classes.invitationContainer}
                  >
                    <span
                      className={[
                        classes.desktop,
                        classes.invitationTypography,
                        classes.invitationTypographyDesktop,
                        classes.whiteText,
                      ].join(' ')}
                    >
                      {t('join.privateInvitation', {
                        username: referralId.split('-')[0],
                      })}
                    </span>
                    <span
                      className={[
                        classes.mobile,
                        classes.cashBackMobileContainer,
                        classes.invitationTypography,
                        classes.whiteText,
                      ].join(' ')}
                    >
                      {t('join.privateInvitation', {
                        username: referralId.split('-')[0],
                      })}
                    </span>
                  </CHRText>
                  <div
                  // className={classes.customWidth7}
                  >
                    <CHRButton
                      onClick={() => setInviteAccepted(true)}
                      label={t('shared.joinChirpyest')}
                      icon={blackLogo}
                      customStyle={[
                        classes.buttonStyles,
                        classes.customWidth7,
                        classes.coloredButton,
                      ].join(' ')}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <form className={classes.container}>
                {gateEnabled ? (
                  <>
                    <CHRText
                      size={TYPOGRAPHY_SIZES.big}
                      align={TYPOGRAPHY_ALIGNMENT.left}
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <span
                        className={[
                          classes.findYourVoiceContainer,
                          classes.desktop,
                        ].join(' ')}
                      >
                        {t('join.findYourVoice')}
                      </span>
                      <span
                        className={[
                          classes.findYourVoiceMobileContainer,
                          classes.mobile,
                        ].join(' ')}
                      >
                        {t('join.findYourVoice')}
                      </span>
                    </CHRText>
                    <CHRText
                      align="center"
                      style={{
                        marginBottom: 0,
                        alignSelf: 'center',
                      }}
                    >
                      <span
                        className={[
                          classes.desktop,
                          classes.cashBackContainer,
                          classes.cashBackDesktopContainer,
                        ].join(' ')}
                      >
                        {t('join.cashBackBrands')}
                      </span>
                      <span
                        className={[
                          classes.mobile,
                          classes.cashBackContainer,
                          classes.cashBackMobileContainer,
                        ].join(' ')}
                      >
                        {t('join.cashBackBrands')}
                      </span>
                    </CHRText>
                    <CHRText align="center">
                      <span
                        className={[
                          classes.desktop,
                          classes.joinNowContainer,
                        ].join(' ')}
                      >
                        {t('join.joinNow')}
                      </span>
                      <span
                        className={[
                          classes.mobile,
                          classes.joinNowContainer,
                          classes.joinNowMobileContainer,
                        ].join(' ')}
                      >
                        {t('join.joinNow')}
                      </span>
                    </CHRText>
                  </>
                ) : (
                  <>
                    <CHRText
                      size={TYPOGRAPHY_SIZES.big}
                      align={TYPOGRAPHY_ALIGNMENT.left}
                      style={{
                        alignSelf: 'center',
                      }}
                    >
                      <span
                        className={[
                          classes.findYourVoiceContainer,
                          classes.desktop,
                        ].join(' ')}
                      >
                        {t('join.findYourVoice')}
                      </span>
                      <span
                        className={[
                          classes.findYourVoiceMobileContainer,
                          classes.mobile,
                        ].join(' ')}
                      >
                        {t('join.findYourVoice')}
                      </span>
                    </CHRText>
                    <CHRText
                      align="center"
                      style={{
                        marginBottom: 0,
                        alignSelf: 'center',
                      }}
                    >
                      <span
                        className={[
                          classes.desktop,
                          classes.cashBackContainer,
                          classes.cashBackDesktopContainer,
                        ].join(' ')}
                      >
                        {t('join.cashBackBrands')}
                      </span>
                      <span
                        className={[
                          classes.mobile,
                          classes.cashBackContainer,
                          classes.cashBackMobileContainer,
                        ].join(' ')}
                      >
                        {t('join.cashBackBrands')}
                      </span>
                    </CHRText>
                    <CHRText align="center">
                      <span
                        className={[
                          classes.desktop,
                          classes.joinNowContainer,
                        ].join(' ')}
                      >
                        {t('join.joinNow')}
                      </span>
                      <span
                        className={[
                          classes.mobile,
                          classes.joinNowContainer,
                          classes.joinNowMobileContainer,
                        ].join(' ')}
                      >
                        {t('join.joinNow')}
                      </span>
                    </CHRText>
                  </>
                )}
                <p className={classes.error}>
                  {formErrors.emailValidationError && formErrors.emailErrorText}
                </p>
                <CHRInput
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  placeholder={t('shared.emailAddress')}
                  type="email"
                  error={formErrors.emailValidationError}
                  required
                  onKeyPress={event =>
                    !isSubmitting && checkIfSubmittable(event, handleSignUp)
                  }
                  autoComplete="new-password"
                />
                <p className={classes.error}>
                  {formErrors.passwordValidationError &&
                    formErrors.passwordErrorText}
                </p>
                <div className={classes.passwordField}>
                  <CHRInput
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value)
                    }}
                    placeholder={t('shared.password')}
                    type={passwordShown ? 'text' : 'password'}
                    error={formErrors.passwordValidationError}
                    required
                    onKeyPress={event =>
                      !isSubmitting && checkIfSubmittable(event, handleSignUp)
                    }
                    autoComplete="new-password"
                  />
                  <i
                    className={classes.passVisibility}
                    onClick={togglePasswordVisibility}
                  >
                    {passwordShown ? eye : eyeSlash}
                  </i>
                </div>
                <p className={classes.passwordGuidelines}>
                  {t('join.passwordGuidelines')}
                </p>
                <p className={classes.error}>
                  {formErrors.requestError && formErrors.requestErrorText}
                </p>
                <CHRButton
                  onClick={handleSignUp}
                  label={t('join.joinWithEmail')}
                  icon={emailIcon}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                  customStyle={classes.buttonStyles}
                />
                <CHRText size="small">or</CHRText>
                <CHRButton
                  onClick={() =>
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    })
                  }
                  label={t('join.joinWithGoogle')}
                  icon={googleIcon}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                  customStyle={classes.buttonStyles}
                />
                {/* <CHRButton
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                label={t('join.joinWithFacebook')}
                icon={facebookIcon}
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                customStyle={classes.buttonStyles}
              /> */}
                <CHRButton
                  onClick={() =>
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Apple,
                    })
                  }
                  label={t('join.joinWithApple')}
                  icon={appleIcon}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                  customStyle={classes.buttonStyles}
                />
                <div className={classes.switches}>
                  <p className={classes.error}>
                    {!isTermsChecked && t('join.termsAndConditions')}
                  </p>
                  <div className={classes.termsWrapper}>
                    <IOSSwitch
                      checked={isTermsChecked}
                      onChange={handleTermsSwitch}
                      name="terms"
                    />
                    <p className={classes.switchLabel}>
                      {t('join.accept')}{' '}
                      <span className={classes.bold}>
                        <a
                          href={ROUTES.terms}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: '#000', textDecoration: 'none' }}
                        >
                          {t('join.termsAndPrivacy')}
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className={classes.termsWrapper}>
                    <IOSSwitch
                      checked={isNewsletterChecked}
                      onChange={handleNewslettersSwitch}
                      name="newsletters"
                    />
                    <p className={classes.switchLabel}>
                      {t('join.subscribeToNewsletter')}
                    </p>
                  </div>
                </div>
              </form>
            )}
          </ContentContainer>
          <div>
            {/*---------------------- why join - title ----------------------*/}
            <CHRText
              size={TYPOGRAPHY_SIZES.big}
              align={TYPOGRAPHY_ALIGNMENT.left}
              style={{
                alignSelf: 'center',
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <span className={classes.desktop}>{t('join.whyJoin')}</span>
              <span
                className={[
                  classes.mobile,
                  classes.whyJoinMobileContainer,
                ].join(' ')}
              >
                {t('join.whyJoin')}
              </span>
            </CHRText>
            {/*---------------------- section 1 - shop millions of products ----------------------*/}
            <section className={classes.shopProductsContainer}>
              <CHRFloatImageLeft
                rightChildren={
                  <div>
                    <Typography
                      variant="h2"
                      className={[
                        classes.customWidth1,
                        classes.customWidth5,
                      ].join(' ')}
                    >
                      {t('whyJoin.shopMillionsOfProductsAndEarn')}
                    </Typography>
                    <div>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={classes.customWidth1}
                      >
                        {t('whyJoin.shopMillionsOfProductsIn')}{' '}
                        <Link
                          to="/category/beauty"
                          className={`${classes.underLinedText} ${classes.clickable}`}
                        >
                          {t('shared.beauty')}
                        </Link>
                        ,{' '}
                        <Link
                          to="/category/fashion"
                          className={`${classes.underLinedText} ${classes.clickable}`}
                        >
                          {t('shared.fashion')}
                        </Link>
                        ,{' '}
                        <Link
                          to="/category/home"
                          className={`${classes.underLinedText} ${classes.clickable}`}
                        >
                          {t('shared.home')}
                        </Link>{' '}
                        {t('shared.and')}{' '}
                        <Link
                          to="/category/lifestyle"
                          className={`${classes.underLinedText} ${classes.clickable}`}
                        >
                          {t('shared.lifestyle')}
                        </Link>{' '}
                        {t('shared.from')}{' '}
                        <Link
                          to="/brands"
                          className={`${classes.underLinedText} ${classes.clickable}`}
                        >
                          {t('shared.topBrands')}
                        </Link>
                        . {t('whyJoin.allCuratedBy')}{' '}
                        <a
                          href="#"
                          className={`${classes.underLinedText} ${classes.clickable}`}
                        >
                          {t('shared.chirpyestStyleFinders')}
                        </a>
                        .
                      </Typography>
                    </div>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      className={classes.customWidth1}
                    >
                      {t('whyJoin.asChirpyestMember')}
                    </Typography>
                    {isInvite && <JoinArrowButton isFloatRight />}
                  </div>
                }
                isGatsbyImg={true}
                image={data.allImageSharp.edges[4].node.fluid}
                withoutButton
              />
            </section>
            {/*---------------------- section 2 - share and earn more cash back! ----------------------*/}
            <CHRSectionContainer>
              <section>
                <CHRFloatImageRight
                  leftChildren={
                    <div>
                      <Typography
                        variant="h2"
                        className={[
                          classes.customWidth2,
                          classes.customWidth6,
                        ].join(' ')}
                      >
                        {t('whyJoin.shareAndEarn')}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={classes.customWidth3}
                      >
                        {t('whyJoin.okayHereIsTheBigTwist')}
                      </Typography>
                      {isInvite && <JoinArrowButton isFloatRight={false} />}
                    </div>
                  }
                  isGatsbyImg={true}
                  image={data.allImageSharp.edges[2].node.fluid}
                  withoutButton
                />
              </section>
            </CHRSectionContainer>
            {/*---------------------- section 3 - earn cash back from your shopping board ----------------------*/}
            <CHRSectionContainer>
              <section>
                <CHRFloatImageLeft
                  rightChildren={
                    <div className={classes.customWidth3}>
                      <Typography variant="h2">
                        {t('whyJoin.earnCashBack')}
                      </Typography>
                      <Typography variant="subtitle1" component="p">
                        {t('whyJoin.saveItems')}
                      </Typography>
                      {isInvite && <JoinArrowButton isFloatRight />}
                    </div>
                  }
                  isGatsbyImg={true}
                  image={data.allImageSharp.edges[5].node.fluid}
                  withoutButton
                />
              </section>
            </CHRSectionContainer>
            {/*---------------------- section 4 - shop the chirpyest’s curated style finds ----------------------*/}
            <CHRSectionContainer>
              <section>
                <CHRFloatImageRight
                  leftChildren={
                    <div>
                      <Typography
                        variant="h2"
                        className={[
                          classes.customWidth4,
                          classes.customWidth5,
                        ].join(' ')}
                      >
                        {t('whyJoin.shopChirpyestCurated')}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={[
                          classes.customWidth4,
                          classes.customWidth5,
                        ].join(' ')}
                      >
                        {t('whyJoin.followChirpyestMembers')}
                      </Typography>
                      {isInvite && <JoinArrowButton isFloatRight={false} />}
                    </div>
                  }
                  isGatsbyImg={true}
                  image={data.allImageSharp.edges[0].node.fluid}
                  withoutButton
                />
              </section>
            </CHRSectionContainer>
            {/*---------------------- section 5 - get inspiration on our blog and to your inbox! ----------------------*/}
            <CHRSectionContainer>
              <section>
                <CHRFloatImageLeft
                  rightChildren={
                    <div>
                      <Typography
                        variant="h2"
                        className={[
                          classes.customWidth4,
                          classes.customWidth5,
                        ].join(' ')}
                      >
                        {t('whyJoin.getInspiration')}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={classes.customWidth4}
                      >
                        {t('whyJoin.exploreOurFeaturedFeeds')}
                      </Typography>
                      {isInvite && <JoinArrowButton isFloatRight />}
                    </div>
                  }
                  isGatsbyImg={true}
                  image={data.allImageSharp.edges[3].node.fluid}
                  withoutButton
                />
              </section>
            </CHRSectionContainer>
            {/*---------------------- section 6 - chirpyest works on mobile too ----------------------*/}
            <CHRSectionContainer>
              <section>
                <CHRFloatImageRight
                  leftChildren={
                    <div>
                      <Typography
                        variant="h2"
                        className={[
                          classes.customWidth2,
                          classes.customWidth5,
                        ].join(' ')}
                      >
                        {t('whyJoin.chirpyestWorksOnMobile')}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={classes.customWidth3}
                      >
                        {t('whyJoin.browseProduct')}
                      </Typography>
                      {isInvite && <JoinArrowButton isFloatRight={false} />}
                    </div>
                  }
                  isGatsbyImg={true}
                  image={data.allImageSharp.edges[1].node.fluid}
                  withoutButton
                />
              </section>
            </CHRSectionContainer>
            {/*---------------------- what are you waiting for - go to top ----------------------*/}
            <CHRSectionContainer>
              <ContentContainer>
                <section className={classes.centered}>
                  <Typography variant="h1" component="h2">
                    {t('home.whatAreYouWaiting')}
                  </Typography>
                  <CHROutlinedButton
                    label={t('shared.backToTop')}
                    font={FONTS.Graphik.GraphikMedium}
                    onClick={() => {
                      window.scroll({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }}
                  />
                </section>
              </ContentContainer>
            </CHRSectionContainer>
          </div>
          <PinExtensionReminderPopOut
            open={isModalOpen}
            handleClose={handleClose}
          />
          <SignInModal
            handleClose={closeSignInModal}
            openStatus={isOpenSignInModal}
            setIsLoggedIn={setIsLoggedIn}
          />
        </Layout>
      )}
    </>
  )
}

export default withTranslation()(Join)

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: [
              "shop-millions-of-products.png"
              "create-share-link.png"
              "your-shopping-board.png"
              "chirpyest-curated-style-finds.png"
              "inspiration-on-our-blog.png"
              "chirpyest-works-on-mobile.png"
            ]
          }
        }
      }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
